<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <DxValidationGroup ref="searchValidationGroup">
          <div class="page_search_box line_bottom_1px">
            <div class="flex space-x-2 inner">
              <div class="flex space-x-2">
                <div class="mt-2">기준년월</div>
                <DxDateBox
                  :edit-enabled="false"
                  :styling-mode="config.stylingMode"
                  width="100"
                  v-model="searchType.customTypes.dayStart"
                  type="date"
                  display-format="yyyy.MM"
                  dateSerializationFormat="yyyyMMdd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  :max="searchType.customTypes.dayEnd"
                  maxLength="10"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                  @value-changed="onDayStartChanged"
                  :calendar-options="{
                    zoomLevel: 'year',
                    minZoomLevel: 'year',
                    maxZoomLevel: 'year',
                  }"
                >
                  <DxValidator>
                    <DxRequiredRule message="기준년월은 필수입니다." />
                  </DxValidator>
                </DxDateBox>
                <div class="mt-1">~</div>
                <DxDateBox
                  :styling-mode="config.stylingMode"
                  width="100"
                  v-model="searchType.customTypes.dayEnd"
                  type="date"
                  display-format="yyyy.MM"
                  dateSerializationFormat="yyyyMMdd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  maxLength="10"
                  :min="searchType.customTypes.dayStart"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                  @value-changed="onDayEndChanged"
                  :calendar-options="{
                    zoomLevel: 'year',
                    minZoomLevel: 'year',
                    maxZoomLevel: 'year',
                  }"
                >
                  <DxValidator>
                    <DxRequiredRule message="기준년월은 필수입니다." />
                  </DxValidator>
                </DxDateBox>
              </div>

              <div class="mt-2">시험계획</div>
              <div>
                <DxSelectBox
                  placeholder="시험계획 선택"
                  :items="scheList"
                  display-expr="scheNm"
                  value-expr="scheId"
                  :styling-mode="config.stylingMode"
                  :width="200"
                  :height="30"
                  @value-changed="onAddSheetName"
                >
                  <DxValidator>
                    <DxRequiredRule message="시험계획은 필수입니다." />
                  </DxValidator>
                </DxSelectBox>
              </div>
              <div class="mt-2">시험지명</div>
              <div>
                <DxTextBox
                  :styling-mode="config.textStylingMode"
                  :value="searchType.textboxTypes.examTypeNm"
                  :width="120"
                  :height="30"
                  :read-only="true"
                >
                </DxTextBox>
              </div>
              <div>
                <DxTextBox
                  :styling-mode="config.textStylingMode"
                  :value="searchType.textboxTypes.examNm"
                  :width="170"
                  :height="30"
                  :read-only="true"
                >
                </DxTextBox>
              </div>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :width="70" :height="30" @click="selectDataList" />
            </div>
          </div>

          <div class="filter">
            <div class="filter-container">
              <div class="item">상세 필터</div>
              <div class="item">
                <DxRadioGroup class="radiobox" :items="config.result" v-model="config.resultAnswer" layout="horizontal" />
              </div>
              <div class="space-x-2">
                <DxTagBox
                  ref="tagBox"
                  placeholder=""
                  :show-selection-controls="false"
                  :search-enabled="false"
                  :data-source="tagBoxData"
                  :value="selectedCondition"
                  display-expr="name"
                  value-expr="id"
                  :multiline="false"
                  height="30"
                  width="550"
                  @value-changed="onTagValueChanged"
                  :styling-mode="config.stylingMode"
                >
                </DxTagBox>
                <DxButton class="btn_XS white outlined add2" text="선택" type="button" :height="30" @click="onOpen" />
                <DxButton class="btn_XS white outlined del1" text="전체삭제" type="button" :height="30" @click="deleteConditions" />
              </div>
            </div>
          </div>
        </DxValidationGroup>
      </div>
      <!-- Top Layer -->

      <esp-dx-data-grid :data-grid="examReport" :ref="examReport.refName" />
    </div>
    <!-- Main Layer -->

    <!-- Agent Modal -->
    <modal-add-agent
      :isOpen="modal.agent.popupVisible"
      :showModalTitle="true"
      :selectedIdList="modal.agent.selectedIdList"
      @closeModal="onClose(false)"
      @saveModal="onSave"
    />
    <!-- Agent Modal -->

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : false
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRadioGroup } from 'devextreme-vue/radio-group';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import modalQuestionDetail from '@/pages/ewm/exam/exam-report/question-report/modal-question-detail.vue';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import modalSelectTargetGroup from '@/pages/ewm/exam/exam-report/group-report/modal-select-target-group.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxTextBox,
      DxSelectBox,
      DxRadioGroup,
      DxTagBox,
      DxPopup,
      DxToolbarItem,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      modalQuestionDetail,
      ModalAddAgent,
      modalSelectTargetGroup,
    },
    props: {},
    watch: {
      'modal.contentData'(val) {
        if (!val.scheId) {
          this.searchType.depts = val.map(i => i.codeId);
        }
      },
      tagBoxData(val) {
        const tagBoxDataSource = val;
        // 부서
        const selectedDeptIds = tagBoxDataSource.filter(tag => tag.type === 'dept').map(tag => tag.id);

        if (selectedDeptIds && selectedDeptIds.length > 0) {
          const selectedDept = this.modal?.contentData.filter(item => selectedDeptIds.includes(item.codeId));

          this.$set(this.modal, 'contentData', selectedDept);
        } else {
          this.$set(this.modal, 'contentData', []);
        }

        // 상담사
        const selectedAgtIds = tagBoxDataSource.filter(tag => tag.type === 'agt').map(tag => tag.id);

        if (selectedAgtIds && selectedAgtIds.length > 0) {
          const selectedAgt = this.modal.agent.targetSelectedIdList.filter(item => selectedAgtIds.includes(item));

          this.$set(this.modal.agent, 'targetSelectedIdList', selectedAgt);
        } else {
          this.$set(this.modal.agent, 'targetSelectedIdList', []);
        }
      },
    },
    data() {
      return {
        selectedCondition: [],
        tagBoxData: [],
        selectedItem: null,
        items: null,
        codes: {
          examSheetType: {
            dataSource: [],
            displayExpr: 'label',
            valueExpr: 'value',
          },
          examQuestionDivision: {
            //문항구분
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          examQuestionDivisionDetail: {
            //문항구분상세
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          examAnswerType: {
            //정답유형
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          examLevel: {
            //난이도
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        config: {
          textStylingMode: 'filled',
          stylingMode: 'outlined',
          result: ['부서', '상담사'],
          resultAnswer: '상담사',
        },
        modal: {
          isOpened: false,
          initData: {},
          contentData: null,
          componentName: '',
          agent: {
            popupVisible: false,
            selectedIdList: [],
            targetSelectedIdList: [],
            targetSelectedDeptList: [],
            originTotal: [],
          },
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          textboxTypes: {},
          depts: [],
          paramsData: null,
        },
        scheList: [], //시험계획리스트
        examReport: {
          keyExpr: 'questionId',
          refName: 'examReport',
          allowColumnResizing: true,
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          // width: '500', // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '문항구분',
              dataField: 'questionDivisionCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '문항구분상세',
              dataField: 'questionDivisionDetailCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '문항내용',
              dataField: 'questionNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerHTML = options.value;
                aTag.addEventListener('click', () => {
                  vm.questionDetailData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '정답유형',
              dataField: 'answerTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '난이도',
              dataField: 'levelCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '정답율',
              dataField: 'answerRate',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              calculateDisplayCellValue: rowData => {
                return `${rowData.answerRate}%`;
              },
            },
            {
              multiHeaderNm: '정답분포',
              columns: [
                {
                  caption: '1',
                  dataField: 'firstAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '2',
                  dataField: 'secondAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '3',
                  dataField: 'thirdAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '4',
                  dataField: 'fourthAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '5',
                  dataField: 'fifthAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
                {
                  caption: '미선택',
                  dataField: 'noneAnswerCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: true,
                },
              ],
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      onTagValueChanged(e) {
        // 선택된 조건들
        const selectedConditions = e.value;

        // 선택되지 않는 조건 삭제
        const unSelectedConditions = vm.tagBoxData.filter(item => !selectedConditions.includes(item.id));

        unSelectedConditions.some(uitem => {
          const index = vm.tagBoxData.indexOf(uitem);
          if (index > -1) {
            vm.tagBoxData.splice(index, 1);
          }
        });
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      onOpen() {
        if (this.config.resultAnswer == '상담사') {
          this.modal.agent.popupVisible = true;
          this.modal.agent.selectedIdList = this.modal.agent.targetSelectedIdList;
        } else {
          this.onOpenModal(
            'modalSelectTargetGroup',
            {
              title: '부서 선택',
              buttons: {
                save: { text: '선택' },
                cancel: { text: '닫기' },
              },
              width: '500',
              height: '800',
            },
            this.searchType.depts,
          );
        }
      },
      deleteConditions() {
        vm.deleteTagboxes()
          .then(() => {
            this.$set(this, 'tagBoxData', vm.tagBoxData);
            this.$set(this, 'selectedCondition', []);
            this.$set(this.searchType, 'depts', []);
          })
          .then(() => {
            vm.selectDataList();
          });
      },
      async deleteTagboxes() {
        vm.tagBoxData.splice(0, vm.tagBoxData.length);
      },
      onConfirmModal() {
        if (!this.modal.contentData.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const deptCodeList = this.modal.contentData;
        this.removeUnSelectedDept(deptCodeList);
        this.addDeptInTagBox(deptCodeList);

        // let obejctArray = [];
        // this.modal.contentData.forEach(i => {
        // 	obejctArray.push(i.codeId);
        // });
        // this.searchType.depts = obejctArray;
        this.isOpenModal(false);
      },
      /** 선택되지 않는 부서 삭제 */
      removeUnSelectedDept(selectedDept) {
        const selectedConditions = selectedDept.map(data => data.codeId);
        const unselectedItems = vm.tagBoxData.filter(item => item.type === 'dept').filter(item => !selectedConditions.includes(item.id));

        unselectedItems.some(uitem => {
          const index = vm.tagBoxData.indexOf(uitem);
          if (index > -1) {
            vm.tagBoxData.splice(index, 1);
          }
        });
      },
      /** tagbox 데이터 추가 */
      addDeptInTagBox(selectedCode) {
        selectedCode.some(code => {
          const isExist = vm.tagBoxData.map(t => t.id).includes(code.codeId);
          if (!isExist) {
            vm.tagBoxData.push({
              id: code.codeId,
              name: code.codeNm,
              type: 'dept',
            });
          }
        });
        this.updateTagBox();
      },
      updateTagBox() {
        this.$set(this, 'tagBoxData', vm.tagBoxData);
        const selectedConditions = vm.tagBoxData.map(data => data.id);
        this.$set(this, 'selectedCondition', selectedConditions);
      },
      onSave(value) {
        vm.removeUnSelectedAgt(value);
        this.addAgtInTagBox(value);

        this.modal.agent.targetSelectedIdList = value.map(item => item.agtid);
        this.onClose();
      },
      /** 선택되지 않는 대상자 tagbox에서 삭제 */
      removeUnSelectedAgt(selectedAgts) {
        // 선택된 대상자들
        const selectedConditions = selectedAgts.map(data => data.agtid);
        // 선택되지 않은 대상자들
        const unselectedItems = vm.tagBoxData.filter(item => item.type === 'agt').filter(item => !selectedConditions.includes(item.id));
        // 선택되지 않은 대상자 삭제
        unselectedItems.some(uitem => {
          const index = vm.tagBoxData.indexOf(uitem);
          if (index > -1) {
            vm.tagBoxData.splice(index, 1);
          }
        });
      },
      addAgtInTagBox(selectedAgts) {
        selectedAgts.some(agt => {
          const isExist = vm.tagBoxData.map(t => t.id).includes(agt.agtid);
          if (!isExist) {
            vm.tagBoxData.push({
              id: agt.agtid,
              name: agt.agtNm,
              type: 'agt',
            });
          }
        });
        this.updateTagBox();
      },
      questionDetailData(data) {
        this.searchType.textboxTypes.questionId = data.questionId;
        this.searchType.textboxTypes.questionNm = data.questionNm;
        this.onOpenModal(
          'modalQuestionDetail',
          {
            title: '문항별 상세',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: '850',
            height: '850',
          },
          this.searchType.textboxTypes,
        );
      },
      /** @description : 그리드 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        const payload = {
          actionname: 'EWM_EXAM_REPORT',
          data: {
            scheId: this.searchType.textboxTypes.scheId,
            sheetId: this.searchType.textboxTypes.examQuestionSheetId,
            agtids: this.modal.agent.targetSelectedIdList,
            deptCds: this.searchType.depts,
          },
          loading: false,
          useErrorPopup: true,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.examReport.dataSource = res.data.data;
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 마감된 시험게획 조회 메서드 */
      async scheDataList() {
        const payload = {
          actionname: 'EWM_EXAM_REPORT_QUESTION_SCHEDULE_LIST',
          data: {
            startDt: this.searchType.customTypes.dayStart,
            endDt: this.searchType.customTypes.dayEnd,
            examProcess: this.$_enums.ewm.examProcess.FINISH.value, //마감계획
          },
          loading: false,
          useErrorPopup: true,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.scheList = res.data.data;
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : selectBox 선택 시 시험지명 넣어주기 */
      onAddSheetName(value) {
        const selectedSche = this.scheList.find(sche => sche.scheId === value.value);
        this.searchType.textboxTypes = selectedSche;
        if (selectedSche) {
          this.searchType.textboxTypes.examTypeNm = this.codes.examSheetType.dataSource.find(
            c => c.value === selectedSche.examSheetType,
          ).label;
          this.searchType.textboxTypes.examNm = selectedSche.examNm;
        }
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 6) + '01';
        this.searchType.customTypes.dayStart = changed;
        this.scheDataList();
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 6) + '29';
        this.searchType.customTypes.dayEnd = changed;
        this.scheDataList();
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList(
          'root_edu_exam_type,root_ewm_edu_exam_question_division,root_ewm_edu_exam_answer_type,root_ewm_edu_exam_level,root_ewm_hr_dept',
        );
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.examSheetType.dataSource = this.$_enums.ewm.examSheetType.values;
          this.codes.examQuestionDivision.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2); //문항구분
          this.codes.examQuestionDivisionDetail.dataSource = this.$_fetchCodesByDepth(
            this.codeMap['root_ewm_edu_exam_question_division'],
            3,
          ); //문항구분상세
          this.codes.examAnswerType.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2); //정답유형
          this.codes.examLevel.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_level'], 2); //난이도

          this.$refs.examReport.getGridInstance.columnOption('questionDivisionCd', 'lookup', this.codes.examQuestionDivision);
          this.$refs.examReport.getGridInstance.columnOption('questionDivisionDetailCd', 'lookup', this.codes.examQuestionDivisionDetail);
          this.$refs.examReport.getGridInstance.columnOption('answerTypeCd', 'lookup', this.codes.examAnswerType);
          this.$refs.examReport.getGridInstance.columnOption('levelCd', 'lookup', this.codes.examLevel);
        });
      },
      /** @description: 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {
        this.scheDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
<style scoped>
  .border-none * {
    border: none;
    width: auto;
  }
  .filter {
    background-color: #f9f9f9;
  }
  .filter-container {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  .item {
    padding: 15px;
  }
</style>
